@import "./styles/common/global";

// @import "./styles/common/navbar";
// @import "./styles/common/sidebar";
// @import "./styles/common/table";
@import "./styles/utils/crop.css";

// @import "./styles/common/footer";

@import "./styles/style.scss";
