.empty-state {
  color: #989898;
  margin: 0 8px;
}

.icon-square {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  background-color: #faf2ff;
  border-radius: 6px;
}

.wrapper {
  transition: all 0.15s ease-in-out;
}

.open {
  opacity: 1;
  visibility: visible;
}

.closed {
  opacity: 0;
  visibility: hidden;
}
