@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

$headerHeight: 44px;

.menu {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  min-width: 187px;
  width: 187px;
  background-color: #f7f8f9;
  font-family: "Roboto";
  border-right: 1px solid #eaeaea;
  height: 100vh;
  overflow-y: auto;

  &__header {
    display: flex;
    width: 100%;
    height: $headerHeight;
    align-items: center;
    padding-left: 14px;
    background-color: #f2f2f2;
    color: #2e3338;
    font-weight: 500;
  }

  &__main-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 10px;
    padding-left: 6px;
    padding-right: 6px;
  }

  &__sub {
    margin-left: 10px;
    width: calc(100% - 10px);
  }

  span {
    &#{".menu__button-text"} {
      color: #989898;
    }
  }

  &__divider {
    height: 1px;
    background-color: #e3e3e3;
    margin: 8px 8px;
  }

  a {
    display: block;
    margin: 2px 0;
  }

  &__static-menu-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin-bottom: 4px;
  }
}
