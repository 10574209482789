.wrapper {
  display: flex;
  align-items: center;
  gap: 15px;
}

.view-wrapper {
  display: flex;
  gap: 15px;
  height: 100%;
}

.divider {
  height: 22px;
  width: 1px;
  background-color: #f7f8f9;
  margin-right: 6px;
}

.button-wrapper {
  display: flex;
  align-items: center;
}
