.global-wrapper {
  margin-bottom: 3px;

  &--grouped {
    padding: 3px;
    background-color: #f7f8f9;
    border-radius: 13px;

    .filter-group-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 3px;
      padding: 0 5px;

      svg {
        fill: #c2c2c2;
      }
    }
  }
}

.flex-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 12px;
  padding: 6px 8px;
  margin-bottom: 3px;
  transition: 0.15s ease-in-out;
  transition-property: background-color, fill, color;
  height: 34px;

  &--small-paddings {
    padding: 6px 5px;

    .button {
      padding: 0;
    }
  }

  &--on-gray-background {
    &:hover:not(.wrapper--active, .wrapper--context-menu-active) {
      background-color: #f7e9ff;
    }

    .icon {
      background-color: #f7e9ff;
    }
  }

  &:hover:not(
      .wrapper--active,
      .wrapper--on-gray-background,
      .wrapper--context-menu-active
    ) {
    background-color: #faf2ff;
  }

  .option-list {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    margin-bottom: 6px;
  }

  .content {
    display: flex;
    align-items: center;
    width: 100%;
    margin-right: 6px;
    max-width: 301px;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 22px;
    min-height: 22px;
    border-radius: 6px;
    margin-right: 6px;

    &:not(.wrapper--on-gray-background .icon) {
      background-color: #faf2ff;
    }

    svg {
      width: 14px;
      height: 14px;
      fill: #c78dff;
    }
  }

  .text {
    max-width: 250px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "Roboto";
    font-size: 14px;
    text-wrap: nowrap;

    .filter-name {
      font-weight: 500;

      &--grey {
        color: #989898;
      }
    }
  }

  .controls {
    display: flex;
    align-items: center;

    .button {
      display: none;
      line-height: 0;
      border: 0;
      background: none;

      &:disabled {
        svg {
          fill: #e4befc;
        }
      }

      &--grip {
        display: block;

        &:not(.button--active):hover {
          svg {
            fill: #c298eb;
          }
        }

        svg {
          transition: fill 0.15s ease-in-out;
          fill: #c2c2c2;
        }
      }

      &:hover:not(:disabled) {
        svg {
          fill: #c298eb;
        }
      }

      &--active {
        svg {
          fill: #9b42f0;
        }
      }
    }
  }

  &:not(.wrapper--active) {
    cursor: pointer;
  }

  &--active {
    padding-top: 3px;
    padding-bottom: 3px;
    background-color: #f0d8ff;
    // margin-bottom: 5px;

    svg {
      transition: fill 0.15s ease-in-out;
      fill: #9b42f0;
    }

    .text {
      color: #9b42f0;
    }

    .controls {
      .button {
        display: block;
        margin-right: 9px;
        cursor: pointer;
        padding-right: 0;
        padding-left: 0;

        &:last-child {
          margin-right: 0;
        }

        &--grip {
          svg {
            fill: #c298eb;
          }
        }
      }
    }
  }

  &--select {
    display: block;
    height: auto;
    padding-top: 6px;
  }

  &--context-menu-active {
    background-color: #f0d8ff;
  }
}
