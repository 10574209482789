.button {
  display: flex;
  align-items: center;
  width: 100%;
  border: 0;
  border-radius: 12px;
  background-color: inherit;
  padding: 6px 8px;
  font-size: 14px;
  font-family: "Roboto";
  line-height: 17px;
  text-align: start;
  box-sizing: border-box;
  transition: all 0.15s ease-in-out;
  height: max-content;

  &_accent {
    .icon {
      svg {
        fill: #9b42f0;
      }
    }
  }

  svg {
    fill: currentColor;
  }

  .icon {
    line-height: 0;

    &--squared {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #faf2ff;
      border-radius: 6px;
      height: 22px;
      width: 22px;

      svg {
        transition: fill 0.15s ease-in-out;
        fill: #c78dff;
      }
    }
  }

  .span {
    transition: all 0.15s ease-in-out;
    margin-left: 6px;
    color: #2e3338;
  }

  .context-menu {
    margin-left: auto;

    svg {
      transition: fill 0.15s ease-in-out;
      fill: #c2c2c2;
    }

    &:hover {
      svg {
        fill: #c298eb;
      }
    }
  }

  &:not(
      .button_active,
      :active,
      :disabled,
      .button_disabled,
      .button_positive,
      .button_negative,
      .button_loading
    ):hover {
    background-color: #faf2ff;
  }

  &:active:not(:disabled, .button_disabled),
  &_active {
    background-color: #f0d8ff;

    .span {
      color: #9b42f0;
    }

    svg,
    .context-menu svg {
      fill: #9b42f0;
    }
  }

  &_condensed {
    width: initial;
  }

  &:disabled,
  &_disabled {
    span {
      color: #989898;
    }
    svg {
      fill: #989898;
    }
  }

  &_inverted {
    background-color: #9b42f0;
    color: #fff;

    svg {
      fill: #fff;
    }

    .span {
      color: #fff;
    }

    &:not(
        .button_active,
        :active,
        :disabled,
        .button_disabled,
        .button_loading
      ):hover {
      background-color: #841de8;
    }
  }

  &_positive {
    background: #27ae60;
    color: #fff;

    svg {
      fill: #fff;
    }

    .span {
      color: #fff;
    }
  }

  &_negative {
    background: #f04343;
    color: #fff;

    svg {
      fill: #fff;
    }

    .span {
      color: #fff;
    }
  }

  &_loading {
    background: #c298eb;
    color: #fff;
    pointer-events: none;
  }
}

.rotate-animation-wrapper {
  display: inline-block;
  animation: rotate 2.8s linear infinite;

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
}
