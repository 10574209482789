.input {
  border-radius: 10px;
  border: 1px solid #faf2ff;
  outline: none;
  padding: 0 8px;
  font-size: 14px;
  width: 100%;
  height: 28px;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
  }
}
