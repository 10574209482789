.wrapper {
  font-family: "Roboto";
  border-bottom: 1px solid #eaeaea;
  padding: 6px 0;
  transition: border-bottom-color 0.15s ease-in-out;

  &_active {
    border-bottom: 1px solid #9b42f0;
  }

  .container {
    display: flex;
    align-items: center;

    .icon {
      display: flex;
      margin-right: 8px;
      width: 16px;
      height: 16px;

      svg {
        width: 16px;
        height: 16px;
      }

      &_search {
        svg {
          fill: #989898;
        }
      }
    }

    .input {
      flex: 1;
      border: none;
      outline: none;
      font-size: 14px;
      font-weight: 400;
      color: #2e3338;
      background-color: transparent;
      padding: 5px 0;
    }
  }
}
