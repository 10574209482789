.wrapper {
  position: absolute;
  top: 40px;
  right: 0;
  transition: all 0.15s ease-in-out;
}

.open {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
}

.closed {
  opacity: 0;
  visibility: hidden;
  transform: translateX(20%);
}
