.select {
  width: 100%;
  border-radius: 10px;
  font-size: 16px;
  font-family: Roboto;

  .option {
    border-radius: 6px;
    padding: 5px 8px;
    font-size: 16px;

    span {
      margin: 0;
    }

    svg {
      fill: #f0d8ff;
    }

    &--selected {
      svg {
        fill: #27ae60;
      }
    }

    &__disabled {
      display: block;
      padding-top: 5px;
      margin: 0;
      font-size: 12px;
      color: #bdc3c7;
    }
  }
}
