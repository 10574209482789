.row {
  display: grid;
  margin-bottom: 2px;
  height: 43px;
  padding-left: 10px;
  gap: 16px;
  border-radius: 10px;
  transition: background 0.15s ease-in-out;

  &.header-row {
    background: #f7f8f9;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    font-size: 14px;
    color: #989898;

    .cell {
      font-size: 14px;
    }

    .cell:first-child {
      font-weight: 500;
    }
  }

  .cell:not(.header-row > .cell):first-child {
    font-weight: 500;
    color: #2e3338;
  }

  &:not(.header-row):not(.active):hover {
    background: #faf2ff;
  }

  &.active {
    background: #f0d8ff;

    .cell {
      color: #9b42f0;
    }
  }
}

.cell {
  align-self: center;
  font-size: 16px;
  font-weight: 400;
  color: #989898;
}

.action-cell {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    transition: fill 0.15s ease-in-out;
    fill: #c2c2c2;
  }
}

.grip-button {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  &:hover {
    svg {
      fill: #c298eb;
    }
  }
}
