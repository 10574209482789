// @import "~normalize.css/normalize";
@import "./_custom-variables.scss";
@import "bootstrap/scss/bootstrap";
@import "bootstrap-icons/font/bootstrap-icons";
@import "../fonts/style.scss";
@import "../blocks/page-construct.scss";
@import "../blocks/type-fields.scss";

// @import "../utils/fonts.scss";

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background-color: #fff3fd !important;
}

.col-np {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.note-editable > p {
  margin-bottom: 0px;
}

html {
  box-sizing: border-box;
}

body {
  margin: 0;
  display: flex;
  flex-direction: column;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}

.wrapper {
  display: flex;
  align-items: stretch;
  width: 100%;
  min-height: 100vh;
}

main {
  flex: 1 1 auto;
  // min-width: 1140px;
  position: relative;
  background: #fff;
  margin-left: 187px; // for sidebar
  font-family: Roboto;

  &.collection {
    background: #f7f8f9;
  }
}

.bg-gray {
  background-color: #f2f2f2;
  hr {
    color: #dee2e6;
    opacity: 1;
  }
}

.breadcrumb-wrapper {
  background-color: #e9ecef;
  padding: 15px;
  border-radius: 3px;
  .breadcrumb {
    margin-bottom: 0;
  }
  margin: {
    top: 20px;
    bottom: 30px;
  }
}

.bold {
  font-weight: 600;
}

.checkbox-group {
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 20px;
  margin-bottom: 10px;
  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.v-hr {
  width: 1px;
  background-color: #dee2e6;
  display: block;
  padding: 0;
}
.w-i {
  width: initial;
}

.card {
  &_no-radius {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
  }

  ul {
    padding: 0px;
    // li {
    //   list-style-type: none;
    // }
  }

  .with-arrow {
    position: relative;
    &::after {
      content: "\f229";
      position: absolute;
      right: 0px;
      display: inline-block;
      font-family: bootstrap-icons !important;
      font-style: normal;
      font-weight: normal !important;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      vertical-align: text-bottom;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
}

.text-editor {
  height: 261px;
}

.card-body .note-editor {
  border: 1px solid #dddddd !important;
  border-radius: 4px;

  .note-toolbar {
    border-bottom: 1px solid #dddddd;
  }
}

.select2-container {
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  width: 1% !important;
  min-width: 0;

  .selection {
    height: 100%;
    .select2-selection {
      height: 100% !important;
      border-color: #ced4da;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }

    .select2-selection__choice__remove {
      height: 100%;
    }
  }

  .select2-selection__arrow {
    height: 100% !important;
  }

  .select2-selection__rendered {
    padding: 0.29rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
  }
}

.banner-img {
  height: 250px;
  background-color: #f7f8f9;
  background-image: url("../../img/camera-fill.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: relative;

  & > .bi {
    position: absolute;
    font-size: 40px;
    left: 20px;
    top: 20px;
  }

  & > .btn-group {
    position: absolute;
    left: 20px;
    bottom: 20px;
  }

  input[type="file"] {
    display: none;
  }

  .banner-img__icons {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;

    .bi {
      width: 16px;

      &.bi-trash-fill {
        color: #d72e3d;
        width: 16px;
      }

      &.bi-pencil-fill {
        color: #bdbdbd;
      }
    }
  }
}

.btn {
  & > input[type="file"] {
    display: none;
  }
}

label {
  &.success {
    margin: 10px 0px;
    color: #28a745;
  }
  &.danger {
    margin: 10px 0px;
    color: #d72e3d;
  }
}

.loading {
  height: 16px;
}

.no-hover {
  --bs-table-hover-bg: rgba(0, 0, 0, 0) !important;
  --bs-table-accent-bg: #fff !important;
  background-color: #fff !important;
  &:hover {
    background-color: #fff !important;
  }
}

.bg-gray-6 {
  background-color: #f2f2f2 !important;
}

.w-fix-c {
  width: 300px;
}

.curs-poin {
  cursor: pointer;
}

.square {
  height: 364px;
  margin: auto;
  overflow: hidden;

  &::before {
    content: "";
    padding-top: 100%;
    float: left;
  }
}

.weight-500 {
  font-family: Helvetica Neue;
  font-weight: 500;
}

.mt-200 {
  margin-top: 200px !important;
}

ul.dropdown-menu.w-parent-abs {
  left: 0px !important;
  right: 0px !important;
}

.fs-24 {
  font-size: 24px;
}

.modal-dialog {
  .modal-content {
    .modal-img {
      max-height: 80vh;
    }
  }
}
.modal-tool {
  padding: 20px 0;

  .img-preview {
    max-width: 210px;
    height: 210px;
    border-radius: 8px;
    margin-bottom: 20px;

    overflow: hidden;
  }
}

.fast-edit-table {
  tbody {
    tr {
      &:nth-of-type(odd) {
        --bs-table-accent-bg: none;
        color: var(--bs-table-striped-color);
      }

      &:nth-of-type(3n) {
        --bs-table-accent-bg: var(--bs-table-striped-bg) !important;
        color: var(--bs-table-striped-color);
      }
    }
  }
}

.plus-list {
  padding-left: 5px;
  button {
    width: 100%;
    border: none;
    background-color: inherit;
    text-align: start;
    padding: 5px 0px;

    span {
      padding-right: 13.5px;
      color: #333333;
      font-size: 14px;
      vertical-align: baseline;
    }
  }
}

.bi-caret-up {
  &.collapsed {
    &::before {
      content: "\f22c";
    }
  }
}

.btn.collapsed {
  i.bi-caret-up-fill::before {
    content: "\f229";
  }
}

.bi-info-square,
.bi-arrow-repeat,
.bi-trash,
.bi-files-alt,
.bi-eye,
.bi-list {
  cursor: pointer;
}

.login {
  width: 100%;
  height: 90vh;
  align-items: center;
  justify-content: center;

  &-win {
    width: 360px;
    padding: 30px;
    background-color: #fff;
    border: 1px solid #dddddd;
    box-sizing: border-box;
    border-radius: 4px;

    .login__logo {
      width: 150px;
      margin-right: auto;
      margin-left: auto;
    }

    & > div {
      margin-bottom: 30px;
    }

    .btn {
      width: 100%;
    }
  }
}

#textBlock {
  border: 1px solid #dddddd;
}

.media-block {
  height: 234px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  background-color: #ffffff;
  border: 1px solid #dddddd;

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  cursor: pointer;

  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  overflow: hidden;

  .bi-trash {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 20px;
    right: 20px;
    transition: all 0.2s linear;
  }

  &:hover {
    .bi-trash {
      opacity: 1;
      visibility: visible;
    }
  }

  img {
    display: block;
    max-width: 100%;

    &.cropper-hidden {
      display: none;
    }
  }

  & > img {
    visibility: hidden;
  }
}

.modal {
  &.show {
    display: block;
  }
}

.jodit-toolbar-editor-collection {
  .jodit-ui-group_group_script,
  .jodit-ui-group_group_clipboard,
  .jodit-ui-group_group_insert,
  .jodit-ui-group_group_search,
  .jodit-ui-group_group_other,
  .jodit-ui-group__h1,
  .jodit-ui-group__h4 {
    display: none;
  }
  .jodit-ui-group_group_indent {
    display: none;
  }
  .jodit-ui-group_group_color {
    display: none;
  }
}

.jodit-wysiwyg {
  p {
    margin: 0px;
  }
}

.jodit-toolbar__box {
  background-color: #fff !important;
}

tr > tr {
  display: none;
}

.text-edit {
  border-bottom: 1px solid #eaeaea;
  background: #ffffff;
  transition: border-color 0.15s ease-in-out;
  cursor: text;

  &:hover {
    border-color: #c298eb;
  }

  &.active {
    border-color: #9b42f0;
  }

  .codex-editor__redactor {
    padding-bottom: 0 !important;

    min-height: 300px;
  }
}

.popup-choose-media {
  position: fixed;
  top: 40px;
  left: 200px;
  width: calc(100vw - 200px);
  background-color: #fff;
  z-index: 10;
  height: calc(100vh - 40px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s linear;

  &.active {
    opacity: 1;
    visibility: visible;
  }
}

.fs14 {
  font-size: 14px;
}
.col-4 {
  .stick-top-head {
    top: 50px;
  }
}

.overlay {
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;

  z-index: 1050;
  background-color: rgba($color: #000000, $alpha: 0);

  &.fade {
    background-color: rgba($color: #000000, $alpha: 0);
    visibility: hidden;
  }

  &.show {
    background-color: rgba($color: #000000, $alpha: 0.5);
    visibility: visible;
  }
}

.role-line {
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 1rem;
  &:last-child {
    border-bottom: none;
    margin-bottom: 0px;
  }
}

.rotate180i {
  &::before {
    transform: rotate(180deg);
  }
}

.sort-button {
  display: flex;
  justify-content: center;
  background: #243b58;
  border-radius: 4px;
  padding: 7px 15.5px;
  color: #fff;
  margin: 12px 14px;

  box-sizing: border-box;

  &:hover {
    color: #fff;
  }
}
.btn {
  &.btn-filters {
    background-color: #1698af;
    color: #fff;

    &:hover {
      color: #fff;
      opacity: 0.9;
    }
  }
}

.flexim-editor-title {
  font-size: 24px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  max-width: 745px;

  &--empty {
    color: #c2c2c2;
  }
}

.flexim-workspace {
  margin-top: 60px;
  margin-bottom: 100px;
  padding: 0 60px;
  width: 100%;
  // background;
}

.flexim-inner-workspace {
  margin: 0 auto;
  background: #fff;
  border-radius: 40px;
  padding: 40px 50px;
  min-width: 500px;
  max-width: 1000px;
}

.flexim-field-wrapper {
  display: flex;
  align-items: center;
  gap: 14px;
  min-height: 44px;
  padding: 3px 0;

  &--align-baseline {
    align-items: baseline;
  }

  &--block {
    display: block;
  }
}

.flexim-field-name {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  color: #b077ff;
  margin: 0;
  width: 100%;
  max-width: 185px;
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;

  &.required {
    &::after {
      content: "*";
      color: #f04343;
      margin-left: 4px;
    }
  }

  &--bold {
    font-weight: 500;
  }

  &--inline {
    max-width: unset;
    margin-bottom: 10px;
  }
}

.flexim-single-component {
  padding: 30px 0;

  &__body {
    border-left: 1px solid #e5e5e5;
    border-radius: 12px;
    padding-left: 30px;

    &--with-right-border {
      border-right: 1px solid #e5e5e5;
      padding-right: 30px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .flexim-field-wrapper {
    margin-bottom: 14px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .flexim-field-name--inline {
    margin-bottom: 20px;
  }
}

.flexim-input {
  border: 1px solid #eaeaea;
  border-radius: 10px;
  padding: 6px 8px;
  width: 100%;
  color: #2e3338;
  outline: none;
  transition: all 0.15s ease-in-out;

  &:hover:not(:disabled) {
    border-color: #c78dff;
  }

  &:focus:not(:disabled) {
    border-color: #9b42f0;
    box-shadow: none;
  }

  &::placeholder {
    color: $secondary;
  }

  &:disabled {
    background-color: #f7f8f9;
    border-color: #eaeaea;
  }
}

.flexim-headline {
  color: #b077ff;
  margin-bottom: 2px;
}

.flexim-media-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 340px;
  border-radius: 12px;
  background: #fdf9ff;
  padding: 4px;
}

.flexim-upload-image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.flexim-uploaded-image {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 290px;
  padding: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.flexim-image-settings {
  align-self: flex-end;
}

.flexim-cropper-tools {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  padding-top: 20px;
  border-top: 1px solid #eaeaea;
  margin-top: 20px;
  margin-right: 24px;
}

.flexim-cropper-tool-button {
  height: 30px;
  outline: none;
  border: 0;
  background-color: transparent;
  font-size: 14px;
  color: #2e3338;
  transition: color 0.15s ease-in-out;
  text-align: left;

  i {
    margin-right: 6px;
    color: #9b42f0;
  }

  &:hover {
    color: #9b42f0;

    i {
      color: #9b42f0;
    }
  }

  &:active {
    color: #841de8;

    i {
      color: #841de8;
    }
  }
}

.flexim-tag {
  display: inline-block;
  padding: 5px 6px;
  border-radius: 6px;
  font-size: 16px;
  line-height: 14px;
  color: #9b42f0;
  background-color: #faf2ff;
  transition: all 0.15s ease-in-out;

  &--with-underline {
    // to make the underline
    background-image: linear-gradient(#9b42f0, #9b42f0);
    background-size: 100% 1px;
    background-repeat: no-repeat;
    background-position: 0 100%;
    padding-bottom: 0px;
  }
}

.flexim-multi-image {
  &__element {
    border: 1px solid #eaeaea;
    border-radius: 14px;
    padding: 4px;
  }
}

.flexim-single-component-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }
}
