.view-wrapper {
  position: relative;
  max-width: 1207px;
  margin: 0 auto;
  height: calc(100% - 98px);
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100% - 40px);
  gap: 20px;
  transition: all 0.15s ease-in-out;

  span {
    font-size: 16px;
    color: #2e3338;
    font-weight: 500;
    font-family: "Roboto";
  }

  &_with-opened-table-control-panel {
    margin-right: 360px;
  }
}
