.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  height: 38px;
}

.left-column {
  display: flex;
  align-items: center;
  gap: 20px;
}

.title {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #4f4f4f;
  font-family: "Roboto";
  margin: 0;
  line-height: 0;
}

.icon-wrapper {
  margin-right: 6px;
}

.button {
  margin-right: 20px;
}

input[type="file"] {
  display: none;
}
