.body{
display: flex;
justify-content: center;
padding-top: 60px;
}

.container-fluid{
max-width: 1000px;
padding: 40px 50px;
position: relative;
width: 100%;
/* Блок с основынм контентом */
}

.column{
width: 100%;
padding-top: 30px;
padding-bottom: 30px;
border-top: 1px solid #EAEAEA;
position: relative;
padding-left: 0px !important;
padding-right: 0px !important;
/* Гоизонтальные блоки с настройками и дивайдерами */
}

.h4{
color: #2E3338;
font-size: 24px;
font-weight: 600;
line-height: 30px;
/* Заголовок старницы */
}

.h6{
color: #2E3338;
font-size: 16px;
font-family: Roboto;
font-weight: 500;
line-height: 22px;
margin-bottom: 4px;
/* Заголовок настроек */
}

.text-secondary{
color: #989898;
font-size: 14px;
font-family: Roboto;
font-weight: 400;
line-height: 16px;
margin-top: 4px;
margin-bottom: 20px !important;
/* Вспомогательный текст настроек */
}

.button-box{
width: auto;
padding: 0px !important;
position:absolute; 
top: 0;
right: 0;
padding-right: 50px !important;
padding-top: 40px !important;
/* Див в котором находится кнопка. Спозиционирована в верхнем правом углу. */
}

.customcard{
border: none;
padding:0px !important;
margin:0px;
width: 100%;
/* Блок в котором хранятся все настройки. Переписана часть старых стилей. */
}

.row{
display: flex;
flex-direction: column;
padding: none !important;
/* Див в который были обернуты все горизонтальные блоки row. Видимо остатки из бутстрапа */
}

.collapsenew{
position: absolute;
top: 30px;
margin-left: 420px;
/* Блоки с раскрывающимися настройками. Абсолютное позиционирование */
}

.page-header{
padding-top: 2px;
padding-bottom: 10px;
padding-left: 0px !important;
padding-right: 0px !important;
/* Div заголовка старницы */
}

.customswitch {
border-radius: 12px;
font-size: 14px;
font-family: Roboto;
font-weight: 400;
padding-left: 14px;
padding-right: 14px;
justify-content: center;
line-height: 14px;
height: 38px;
/* Общие стили для свитч баттона */
&.primary {
background-color: #FAF2FF;
color: #9B42F0;
/* Стили для активного свитч баттона */
}
&.light {
background-color: white;
color: #989898;
border-color: #EAEAEA;
/* Стили для дефолтного состояния свитч баттона */
}
}

.inputandname{
display: block;
margin-bottom: 0px !important;
margin-top: 2px;
/* Блок лейбла и инпута */
}

.inputcompress{
width: 176px !important;
border-radius: 10px !important;
border-color: #EAEAEA;
margin-top: px;
/* Стили инпута для сжатия */
}

.inputadapt{
width: 84px !important;
border-radius: 10px !important;
border-color: #EAEAEA;
/* Стили инпута для адаптивов */
}

.input-name{
color: #B077FF;
font-size: 16px;
font-family: Roboto;
font-weight: 400;
line-height: 16px;
padding-bottom: 4px !important;
/* Стили для лейблов инпута */
}

.container-horizontal{
display: flex;
gap: 8px;
/* div в котором горизонтально лежат инпуты для адаптивов */
}
