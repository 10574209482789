.main {
  padding-top: 25px;
  font-family: Roboto;
}

.content {
  max-width: 1207px;
  margin: 0 auto;
  padding: 0 4%;
  box-sizing: content-box;
  height: calc(100% - 50px);
}

.wrapper {
  position: relative;
  height: calc(100% - 47px);
}

.title {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #2e3338;
}

.icon {
  margin-right: 6px;
  color: #989898;
}

.table-views-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid #eaeaea;
}

.view-name {
  display: flex;
  color: #2e3338;
  border: 1px solid transparent;
  align-items: center;
  height: 100%;
  position: relative;
  height: 39px;
  top: 1px;
  gap: 4px;
  cursor: pointer;
  max-width: 140px;
  transition: all 0.15s ease-in-out;
  border-bottom-color: #2e3338;
}
