.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid #eaeaea;
}

.right-side {
  display: flex;
  align-items: center;

  .button {
    svg {
      color: #9d51ff;
      transition: fill 0.15s ease-in-out;
    }
  }

  .reset-button {
    margin-right: 16px;
  }
}

.add-new-button {
  background: linear-gradient(235.38deg, #ff5151 2.57%, #9d51ff 124.74%);
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 500;
  border-radius: 20px;
  padding: 0 12px;
  color: #fff;
}
