.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
}

.title {
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  margin-right: 14px;
}

.right-side,
.left-side {
  display: flex;
  align-items: center;
}

.close-button {
  display: flex;
  cursor: pointer;

  svg {
    width: 18px;
    height: 18px;
    fill: #c2c2c2;
    transition: all 0.15s ease-in-out;
  }

  &:hover {
    svg {
      fill: #c298eb;
    }
  }
}
