.toggle-switch {
  display: inline-flex;
  border-radius: 20px;
  font-size: 14px;
  font-family: "Roboto";

  &--on-gray-background {
    .label {
      &--active {
        background-color: #f7e9ff;
      }
    }
  }

  &--default {
    padding: 0;
  }

  &--inverted {
    padding: 0;

    .label {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background-color: #fff;

      &--active {
        color: #fff;
        background-color: #9b42f0;
        border: 1px solid transparent;

        &:hover {
          color: #fff;
        }
      }

      &:not(.label--active) {
        color: #c298eb;

        &:hover {
          color: #9b42f0;
        }
      }
    }
  }

  &--big {
    padding: 0;
  }
}

.input {
  display: none;
}

.label {
  cursor: pointer;
  padding: 0 10px;
  border: 1px solid transparent;
  transition: all 0.15s ease;

  &--active {
    background-color: #faf2ff;
    color: #9b42f0;
  }

  &:first-child {
    border-radius: 20px 0 0 20px;
  }

  &:last-child {
    border-radius: 0 20px 20px 0;
  }

  &:not(.label--active) {
    border: 1px solid #eaeaea;
    color: #989898;
  }

  &:hover {
    color: #9b42f0;
  }
}
