.button {
  position: relative;
  top: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  cursor: pointer;
  border-bottom: 1px solid transparent;
  transition: all 0.15s ease-in-out;
  padding: 0 12px;

  &_with-margin-right {
    margin-right: 16px;
  }

  &:hover {
    color: #9b42f0;

    svg {
      fill: #9b42f0;
    }
  }

  svg {
    fill: #989898;
    transition: all 0.15s ease-in-out;
  }

  &_active {
    color: #9b42f0;
    border-bottom-color: #9b42f0;

    svg {
      fill: #9b42f0;
    }

    .counter-wrapper {
      .button-divider {
        background-color: #9b42f0;
      }
    }
  }

  &_default-with-counter {
    color: #c298eb;
    border-bottom-color: transparent;

    svg {
      fill: #c298eb;
    }

    .counter-wrapper {
      .button-divider {
        background-color: #c298eb;
      }
    }
  }

  .counter-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .button-divider {
      height: 20px;
      margin: 0 8px;
      transition: all 0.15s ease-in-out;
    }
  }
}

.add-new-button {
  background: linear-gradient(235.38deg, #ff5151 2.57%, #9d51ff 124.74%);
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 500;
  border-radius: 20px;
  padding: 0 12px;
  color: #fff;
}
