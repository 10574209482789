.view-type {
  display: flex;
  color: #989898;
  border: 1px solid transparent;
  align-items: center;
  height: 100%;
  position: relative;
  height: 39px;
  top: 1px;
  gap: 4px;
  cursor: pointer;
  max-width: 140px;
  transition: all 0.15s ease-in-out;

  &--active {
    color: #2e3338;
    border-bottom-color: #2e3338;
  }

  &:hover {
    color: #2e3338;
  }

  .configuration-name {
    min-width: 20px;
    max-width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
  }

  .context-menu-wrapper {
    position: fixed;
    transition: opacity 0.15s ease-in-out;
    padding: 4px;
    border: 1px solid #eaeaea;
    box-shadow: 0px 2px 6px 0px #00000014;
    border-radius: 10px;
    background-color: #ffffff;
    z-index: 100;
  }

  .context-menu {
    cursor: pointer;

    svg {
      fill: #c2c2c2;
    }
  }

  .tooltip {
    background: #f0d8ff;
    color: #9b42f0;
    border-radius: 12px;
    padding: 5.5px 8px;
    font-size: 12px;
    line-height: 14.5px;
    z-index: 1;
  }
}
