.wrapper {
  padding: 0 10px 15px;
  background-color: #ffffff;
  border-left: 1px solid #eaeaea;
  width: 360px;
  overflow-y: auto;

  .header-wrapper {
    position: sticky;
    top: 0;
    padding-top: 20px;
    background-color: #ffffff;
  }
}
