.wrapper {
  display: flex;
  align-items: center;
  height: 100%;
}

.search {
  display: flex;
  align-items: center;

  input {
    height: 32px;
    margin-right: 4px;
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px;
    width: 32px;
    height: 32px;
    cursor: pointer;

    svg {
      fill: #989898;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      svg {
        fill: #c298eb;
      }
    }
  }
}
