.wrapper {
  position: fixed;
  display: flex;
  align-items: center;
  gap: 6px;
  background: #f0d8ff;
  padding: 3px 8px;
  border-radius: 12px;
  height: 34px;
  // opacity: 0;
  transition: opacity 0.15s ease-in-out;
  width: 240px;

  .submit-button,
  .cancel-button {
    cursor: pointer;
    line-height: 0;
  }

  .cancel-button svg {
    fill: #c298eb;
  }

  .submit-button {
    svg {
      transition: fill 0.15s ease-in-out;
      fill: #9b42f0;
    }

    &--disabled {
      cursor: initial;
      svg {
        fill: #e4befc;
      }
    }
  }

  .controls {
    display: flex;
    gap: 8px;
    align-items: center;
  }
}
