.select {
  width: 100%;
  border-radius: 10px;
  font-size: 14px;
  font-family: Roboto;
  height: 28px;

  .option {
    svg {
      fill: #f0d8ff;
    }

    &--selected {
      svg {
        fill: #27ae60;
      }
    }
  }
}
