.input {
  width: 100%;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  outline: none;
  font-size: 16px;
  height: 38px;
  padding: 10px 8px;
  transition: all 0.15s ease-in-out;
  box-sizing: border-box;

  appearance: none;
  -moz-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    // для Chrome
    -webkit-appearance: none;
    margin: 0;
  }

  &:hover {
    border-color: #f0d8ff;
  }

  &:focus {
    border-color: #9b42f0;
  }

  &--condensed {
    padding-top: 0;
    padding-right: 8px;
    padding-bottom: 0;
    padding-left: 8px;
    font-size: 14px;
    height: 28px;
  }
}
