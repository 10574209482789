.wrapper {
  position: absolute;
  padding: 4px;
  background: white;
  border-radius: 10px;
  border: 1px solid #eaeaea;
  opacity: 0;
  transition: opacity 0.15s ease-in-out;

  &.active {
    opacity: 1;
  }
}
