.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 6px;
  border-radius: 6px;
  background-color: #faf2ff;
  gap: 6px;
}

.label {
  font-size: 14px;
  font-family: "Roboto";
  color: #9b42f0;
}

.close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background-color: transparent;
  padding: 0;
}
