.draggable-wrapper {
  svg {
    fill: #9b42f0;
    transition: fill 0.15s ease-in-out;
  }

  &_disabled {
    .button-inner-content {
      color: #989898;
      transition: color 0.15s ease-in-out;
    }

    svg {
      fill: #c2c2c2;
    }
  }
}

.button-inner-content {
  display: flex;
  align-items: center;

  svg {
    fill: #c78dff;
  }
}

.search {
  margin-bottom: 15px;
}

.counter {
  font-family: "Roboto";
  font-weight: 500;
  padding: 0 8px;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 15px;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #faf2ff;
  border-radius: 6px;
  height: 22px;
  width: 22px;
  margin-right: 6px;

  svg {
    transition: fill 0.15s ease-in-out;
    fill: #c78dff;
  }
}
