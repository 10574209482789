$cellWidth: 530px; // 400px (max leading cell width) + 120px (gap) + 10px (padding)
$headerBgColor: #f7f8f9;
$headerTextColor: #989898;

.image-cell {
  background-color: #fff;
  border: 0.5px solid #d7d7d7;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 23px;
  width: 23px;

  svg {
    width: 13px;
    height: 13px;
  }
}

.tag {
  display: inline-block;
  padding: 6px;
  border-radius: 6px;
  font-size: 16px;
  line-height: 14px;
  color: #c298eb;
  background-color: #faf2ff;
  margin-right: 4px;
  transition: all 0.15s ease-in-out;

  &--with-underline {
    // to make the underline
    background-image: linear-gradient(#c298eb, #c298eb);
    background-size: 100% 1px;
    background-repeat: no-repeat;
    background-position: 0 100%;
    padding-bottom: 0px;
  }
}

.flex-table {
  display: flex;
  flex-direction: column;
  // overflow-x: auto;
}

.list-wrapper {
  overflow-y: auto;
  height: calc(100vh - 217px);
}

.flex-row {
  display: flex;
  width: 100%;
  height: 44px;
  border-radius: 10px;
  background-color: #fff;
  color: #989898;
  gap: 16px;
  transition: fill, background-color 0.15s ease-in-out;
  cursor: pointer;

  svg {
    transition: fill 0.15s ease-in-out;
  }

  &:not(.header-wrapper) {
    margin-bottom: 2px;
  }

  &:hover:not(.header, .active) {
    background-color: #faf2ff;
  }

  &:not(.header) .first-column {
    a {
      color: #2e3338;
      font-weight: 500;
      // to make the underline
      background-image: linear-gradient(#eaeaea, #eaeaea);
      background-size: 100% 1px;
      background-repeat: no-repeat;
      background-position: 0 100%;
      padding-bottom: 3px;
    }
  }

  .flex-cell {
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 44px;

    &:last-child {
      padding-right: 0;
      min-width: 38px;
      max-width: 38px;
      margin-left: auto;
    }

    svg {
      fill: #c2c2c2;
    }

    &--with-content {
      svg {
        fill: #9b42f0;
      }
    }
  }

  .icon-column {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    max-width: 32px;
    line-height: 0;
  }

  .content-column {
    flex: 1 1;
    max-width: 140px;
  }

  &.active {
    background-color: #f0d8ff;
    color: #9b42f0;

    .flex-cell:not(.flex-cell--with-content) {
      svg {
        fill: #9b42f0;
      }

      .tag {
        color: #9b42f0;
      }
    }
  }
}

.grip-column {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.header-wrapper {
  background-color: $headerBgColor;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-bottom: 2px;
  // min-width: max-content;
  // word-break: break-word;
  // overflow-wrap: break-word;

  .flex-row {
    background-color: transparent;
  }
}

.header {
  .icon-column {
    svg {
      fill: $headerTextColor;
    }
  }

  .first-column.flex-cell {
    font-weight: 500;
  }
}

.first-column {
  padding-left: 10px;
  min-width: 300px;
  flex: 1 1;
}

.wrapper .tooltip {
  background-color: #f0d8ff;
  background: #f0d8ff;
  color: #9b42f0;
  border-radius: 12px;
  padding: 5.5px 8px;
  font-size: 12px;
  line-height: 14.5px;
}
