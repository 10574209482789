.info-text {
  color: #2e3338;
  font-size: 14px;
  padding-top: 4px;
}

.info-text--error {
  color: #f04343;
}

.info-text--info {
  color: inherit;
}
