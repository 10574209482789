.button {
  border: 0;
  background: 0;
  height: 38px;
  width: 24px;
  box-sizing: border-box;

  svg {
    transition: fill 0.15s ease-in-out;
    fill: currentColor;
  }
}
