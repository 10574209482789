.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  border-radius: 14px;
  border: 0;
  background: inherit;
  font-family: "Roboto";
  line-height: unset;
  text-align: center;
  box-sizing: border-box;
  transition: all 0.15s ease-in-out;
  height: max-content;

  &:disabled {
    background: #f7f8f9;
    color: #989898;
  }

  .icon-wrapper {
    display: flex;
    margin-left: 8px;

    &--before {
      margin-right: 8px;
      margin-left: 0;
    }

    &--after {
      margin-right: 0;
      margin-left: 8px;
    }

    svg {
      fill: currentColor;
    }
  }

  &--accent {
    color: #9b42f0;
    background: #faf2ff;

    &:hover:not(:disabled) {
      background: #f0d8ff;
    }
  }

  &--neutral {
    background: #f3f0f5;
    color: #2e3338;

    &:hover:not(:disabled) {
      background: #eaeaea;
    }
  }

  &--outlined {
    border: 1px solid #f3f0f5;
    background: transparent;
    color: #2e3338;
    padding-top: 6px;
    padding-bottom: 6px;

    &:hover:not(:disabled) {
      border-color: #eaeaea;
    }

    &:disabled {
      background: transparent;
      color: #989898;
    }
  }

  &--inverted {
    background: #9b42f0;
    color: #fff;

    &:hover:not(:disabled) {
      background: #841de8;
    }

    &:active {
      background: #6301c2;
    }

    &:disabled {
      background: #c2c2c2;
      color: #ffffff;
    }
  }

  &--default {
    padding: 5px 18px;
    font-size: 16px;
  }

  &--small {
    padding: 2px 8px;
    font-size: 14px;
  }

  &--full-width {
    width: 100%;
    padding: 5px 18px;
  }

  &--positive {
    color: #fff;
    background: #27ae60;
  }

  &--negative {
    background: #feecec;
    color: #dc3545;

    &:hover:not(:disabled) {
      background: #ffd4d4;
    }
  }

  &--negative-high-contrast {
    background: #f04343;
    color: #fff;

    &:hover:not(:disabled) {
      background: #ca0c0c;
    }
  }
}
