.trigger {
  display: flex;
  height: 39px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #989898;
  transition: color 0.15s ease-in-out;
  user-select: none;
  gap: 8px;

  &:hover,
  &_active {
    color: #2e3338;

    svg {
      fill: #2e3338;
    }
  }
}

.dropdown {
  position: fixed;
  background-color: #fff;
  border: 1px solid #f0d8ff;
  border-radius: 10px;
  padding: 4px;
  max-width: 240px;
  min-width: 240px;

  .option-button {
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
