.type-fields {
  & > h6 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0;
    color: #2e3338;
  }
  .type-fields__cont {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 14px;
    padding-top: 20px;
  }

  .type-fields__elem {
    display: flex;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.15s ease-in-out;

    &:hover {
      background: #faf2ff;
    }

    .badge {
      background: #faf2ff;
      border-radius: 8px;
      margin-right: 10px !important;
      font-size: 16px;
      padding: 8px;
      width: 34px;
      height: 34px;
      .bi {
        color: #c78dff;
      }
    }

    p {
      font-size: 16px;
      color: #2e3338;
      line-height: 16px;
      margin: 0px 15px;
      margin-left: 0px;
    }

    span {
      font-size: 14px;
      color: #989898;
      line-height: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
