@font-face {
  font-family: "icomoon";
  src: url("./icomoon.eot?q5xpxk");
  src: url("./icomoon.eot?q5xpxk#iefix") format("embedded-opentype"),
    url("./icomoon.ttf?q5xpxk") format("truetype"),
    url("./icomoon.woff?q5xpxk") format("woff"),
    url("./icomoon.svg?q5xpxk#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  & > * {
    font-size: 30px !important;
  }
}

.i-field-json .path1:before {
  content: "\e900";
  color: rgb(134, 142, 150);
}
.i-field-json .path2:before {
  content: "\e901";
  margin-left: -1.1337890625em;
  color: rgb(255, 255, 255);
}
.i-field-json .path3:before {
  content: "\e902";
  margin-left: -1.1337890625em;
  color: rgb(255, 255, 255);
}
.i-field-email .path1:before {
  content: "\e903";
  color: rgb(236, 0, 156);
}
.i-field-email .path2:before {
  content: "\e904";
  margin-left: -1.1337890625em;
  color: rgb(255, 255, 255);
}
.i-field-password .path1:before {
  content: "\e905";
  color: rgb(235, 0, 0);
}
.i-field-password .path2:before {
  content: "\e906";
  margin-left: -1.1337890625em;
  color: rgb(255, 255, 255);
}
.i-field-enumeration .path1:before {
  content: "\e907";
  color: rgb(74, 0, 148);
}
.i-field-enumeration .path2:before {
  content: "\e908";
  margin-left: -1.1337890625em;
  color: rgb(255, 255, 255);
}
.i-field-media .path1:before {
  content: "\e909";
  color: rgb(136, 223, 25);
}
.i-field-media .path2:before {
  content: "\e90a";
  margin-left: -1.1337890625em;
  color: rgb(255, 255, 255);
}
.i-field-text .path1:before {
  content: "\e90b";
  color: rgb(221, 199, 0);
}
.i-field-text .path2:before {
  content: "\e90c";
  margin-left: -1.1337890625em;
  color: rgb(255, 255, 255);
}
.i-field-richtext .path1:before {
  content: "\e90d";
  color: rgb(217, 104, 0);
}
.i-field-richtext .path2:before {
  content: "\e90e";
  margin-left: -1.1337890625em;
  color: rgb(255, 255, 255);
}
.i-field-richtext .path3:before {
  content: "\e90f";
  margin-left: -1.1337890625em;
  color: rgb(255, 255, 255);
}
.i-field-number .path1:before {
  content: "\e910";
  color: rgb(19, 84, 210);
}
.i-field-number .path2:before {
  content: "\e911";
  margin-left: -1.1337890625em;
  color: rgb(255, 255, 255);
}
.i-field-number .path3:before {
  content: "\e912";
  margin-left: -1.1337890625em;
  color: rgb(255, 255, 255);
}
.i-field-number .path4:before {
  content: "\e913";
  margin-left: -1.1337890625em;
  color: rgb(255, 255, 255);
}
.i-field-date .path1:before {
  content: "\e914";
  color: rgb(23, 171, 73);
}
.i-field-date .path2:before {
  content: "\e915";
  margin-left: -1.1337890625em;
  color: rgb(255, 255, 255);
}
.i-field-date .path3:before {
  content: "\e916";
  margin-left: -1.1337890625em;
  color: rgb(255, 255, 255);
}
.i-field-boolean .path1:before {
  content: "\e917";
  color: rgb(0, 201, 214);
}
.i-field-boolean .path2:before {
  content: "\e918";
  margin-left: -1.1337890625em;
  color: rgb(255, 255, 255);
}
.i-field-relation .path1:before {
  content: "\e919";
  color: rgb(153, 17, 217);
}
.i-field-relation .path2:before {
  content: "\e91a";
  margin-left: -1.1337890625em;
  color: rgb(255, 255, 255);
}
