.page-construct {
  &__list {
    height: 100vh;
    overflow: auto;
    padding: 30px 20px;
    border-right: 1px solid #eaeaea;

    .page-construct__collaps-btn {
      margin-top: 40px;
      font-family: "Helvetica Neue";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }

    ul {
      padding: 0px;
      padding-left: 0px;
      margin: 0px -10px;
      list-style: disc inside;

      li {
        padding: 10px 0px;
        padding-left: 18px;
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 12px;

        position: relative;

        &:hover {
          background-color: #e9ecef;
        }
      }
    }
  }

  &__element-wrapper {
    padding: 10px;
    border-radius: 12px;
    background: #f7f8f9;

    .watch-all {
      color: #9b42f0;
      transition: all 0.15s ease-in-out;

      &:hover {
        background-color: #f7e9ff;
      }
    }
  }

  &__fields {
    height: 100vh;
    overflow: auto;
  }

  .element-list {
    padding: 0px;
    & > .elem-point {
      display: flex;
      justify-content: space-between;

      padding: 10px 15px;

      .left-block {
        display: flex;
        p {
          margin: 0px;

          span {
            color: #868e96;
          }
        }
      }
    }
  }

  .slash {
    border-bottom: 1px solid #dee2e6;
  }

  .check-discl {
    font-size: 14px;
    line-height: 1.25;
    color: #989898;
  }

  &__trash-icon {
    color: #c2c2c2;
    transition: color 0.15s ease-in-out;
    font-size: 18px;
    &:hover {
      color: #c298eb;
    }
  }
}

.edit-field {
  p {
    // margin-bottom: 10px;
  }

  .description {
    color: #868e96;
    font-size: 13px;
  }
  .form-check__radio {
    border: 1px solid #eaeaea;
    border-radius: 10px;
    display: flex;
    overflow: hidden;
    padding: 8px 14px 6px;
    transition: border-color 0.15s ease-in-out;
    cursor: pointer;

    &--active {
      border-color: #9b42f0;
    }

    input {
      display: none;
      margin: auto 12px;
    }

    p {
      background-color: #fff;
      width: 100%;
      font-size: 16px;
      line-height: 22px;
      color: #2e3338;
      margin: 0px;
      span {
        display: block;
        color: #989898;
        font-size: 14px;
        line-height: 1.25;
      }
    }
  }

  .select-modal {
    width: 100%;
  }
}
