.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  height: 50px;
  width: calc(100% - 187px);
  box-shadow: 3px 0px 6px 0px #00000014;
  background-color: #fff;
  font-family: "Roboto";
  padding-left: 40px;
  padding-right: 20px;

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  .page-item {
    height: 21px;
    font-size: 16px;
    line-height: 21px;
    color: #2e3338;
    margin-right: 2px;
    transition: all 0.15s ease-in-out;
    border: 0;
    background-color: transparent;
    padding: 0 4px;

    &.page-regulator {
      min-width: 25px;
    }

    &:hover:not(.page-item_active, .page-item_decorative) {
      color: #c298eb;
    }

    &_active {
      font-size: 16px;
      font-weight: 500;
      color: #9b42f0;
    }
  }

  .per-page-controll-container {
    display: flex;
    align-items: center;
  }

  .text {
    margin-right: 15px;
    color: #c2c2c2;
  }

  .input-container {
    width: 60px;
  }
}
