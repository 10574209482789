@import "./common/custom-variables.scss";

a {
  text-decoration: none !important;
}

.block-1 {
  max-width: 160px !important;
}

.block-2 {
  max-width: 300px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.pointer {
  cursor: pointer !important;
}

.cursor-drag {
  cursor: grab !important;
}

.bi-list {
  cursor: grab !important;
}

.navbar-search {
  position: absolute;
  margin-right: 140px;
  padding-right: 50px;
  height: 100%;
  background-color: #282d32;
  border: none;
}

.email-badge {
  background-color: #ec009c;
}

.img-badge {
  background-color: #88df19;
}

.nav-dash {
  position: relative;
  background-color: #868e96;
  width: 31px;
  height: 1px;
  top: calc(50% - 5px);
  left: -41px;
}

.no-dash:last-child .child::after {
  position: absolute;
  left: -45px;
  top: 20.5px;
  content: "";
  width: 10px;
  height: 20px;
  background-color: rgb(255, 255, 255);
}

.list-elem {
  align-items: center;
}

.btn-newfield::after {
  position: absolute;
  content: "";
  width: 10px;
  height: 25px;
  background-color: rgb(255, 255, 255);
  left: -20px;
  top: 25px;
}

.constr-dash {
  background-color: #f4f4f4;
  width: 31px;
  height: 2px;
  position: relative;
  top: 15px;
  left: -24px;
}

.comp-block-list__elems {
  border-left: 1px solid #eaeaea;
}

.comp-block-list__elems > div {
  position: relative;
}

.comp-block-list__elems > div::after {
  content: "";
  position: absolute;
  top: 20px;
  left: -15px;
  width: 15px;
  height: 2px;
  background-color: #eaeaea;
}

.comp-block-list__elems > div:last-child::before {
  transition: all 0.15s ease-in-out;
  content: "";
  position: absolute;
  width: 30px;
  height: calc(100% - 20px);
  bottom: 0;
  left: -20px;
  background-color: white;
}
.comp-block-list__elems > div > .badge {
  margin-left: 15px;
}

.comp-block-list {
  &:hover {
    .comp-block-list__elems > div:last-child::before {
      background-color: #faf2ff;
    }
  }

  .comp-block-list__elems {
    .list-elem {
      position: relative;
      pointer-events: none;

      &:last-child {
        pointer-events: all;
      }

      .dash {
        display: block;
      }

      .bi-trash {
        display: none;
      }
    }
  }
}

.crumb-bg {
  background-color: #e9ecef !important;
  border-radius: 4px;
}

.drop-bg {
  background-color: #f2f2f2;
}

.construct-border {
  border-color: #f2f2f2;
}

.product-card {
  border-top: 1px solid rgb(234, 234, 234);
  border-bottom: 1px solid rgb(234, 234, 234);
  padding: 8px 0;
  margin: 6px 0 12px;

  & > div {
    align-items: center;
    border-radius: 10px;
    transition: all 0.15s ease-in-out;
    margin-bottom: 2px;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background: #faf2ff;
    }

    .badge {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 26px;
      height: 26px;
      box-sizing: border-box;
      .bi {
        font-size: 16px;
      }
      background: #faf2ff;
      border-radius: 8px;
      color: #c78dff;
    }
  }
}

.modal-content {
  border-radius: 20px;
  border: 1px solid #eaeaea;
  background-clip: unset;
}

.modal-body {
  padding: 30px 20px;
}

.modal-footer {
  padding-top: 10px;
  padding-bottom: 10px;
}

.form-check-input {
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  border-color: #c298eb;
}

.form-check-input:checked {
  background-color: #9b42f0;
  border-color: #9b42f0;
}

.form-check-input:focus {
  border-color: #c298eb;
  box-shadow: none;
}

.block-group {
  font-size: 14px;
  line-height: 16px;
  background-color: #f7f8f9;
  height: 34px;
  padding: 3px 9px;
  align-items: center;
  min-width: 140px;
  border-radius: 12px;
}

.block-group-input {
  width: 80px;
}

.block-group-left {
  margin-right: 6px;
  svg {
    fill: #c2c2c2;

    &:hover {
      fill: #c298eb;
    }
  }
}

.block-group-right {
  margin-left: 6px;
  color: #c2c2c2;

  &:hover {
    color: #c298eb;
  }
}

.block-group-button {
  width: auto !important;
}

.dashed {
  border: 1px dashed #ced4da;
  height: 70px;
}

.block-group-btn {
  display: flex;
  align-items: center;
  height: 16px !important;
  width: 16px !important;
  border-radius: 100%;
  background-color: #dddddd;
  transform: translateX(10px);
  padding: 0 !important;
  text-align: center;
}

.rotate.bi-plus::before {
  transform: rotate(45deg);
}

.block-group-badge {
  height: 30px;
  width: 34px;
  font-size: 15px !important;
  padding-top: 7px !important;
}

.text_small {
  font-size: 12.8px;
}

.tr-title {
  font-weight: 500;
  color: #275382;
}

.th-title {
  font-weight: 700;
  color: #868e96;
}

.input-group_small {
  width: 150px !important;
}

.modal-collection {
  position: absolute;
  top: 191px;
  right: 495px;
  background-color: #fff;
  border: 1px solid #ced4da;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  padding: 15px;
  width: 290px;
}

.activeRadio {
  background-color: #007bff !important;
  color: white !important;
}

.modal2-collection {
  position: absolute;
  top: 0px;
  right: -600px;
  width: 600px;
  height: 100vh;
  background-color: #fff;
  border: 1px solid #ced4da;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: right 0.3s linear;
  overflow: auto;

  &.w-700 {
    width: 700px;
    right: -700px;
  }

  &.show {
    right: 0px;
  }

  .x-position {
    right: 40px;
    top: 25%;
  }
}

.input-group.col-8 {
  width: 66.666667% !important;
}

.input-group.col-4 {
  width: 33.33333% !important;
}

.filters {
  margin-top: -10px;
}

.filters-input {
  height: 73% !important;
  width: 256px;
}

.table-checkbox {
  width: 30px;
}

.th-naming {
  padding-right: 160px !important;
}

textarea {
  height: 200px;
}

.collection-banner {
  font-size: 140px;
}

.card-bg {
  background-color: rgba(206, 212, 218, 1) !important;
}

.multi-component-left {
  // background-color: rgba(233, 236, 239, 1);
  display: flex;
  align-items: center;
  padding-right: 20px;

  svg {
    fill: #989898;
  }
}

.multi-component-right {
  color: #989898;
  padding-left: 36px;
}

.opencollection-input::placeholder {
  color: black !important;
}

.email-label {
  margin-top: 6px;
  font-size: 14px;
}

.fs-14 {
  font-size: 14px;
}

.fs-12 {
  font-size: 12px;
}

.btn-sm {
  font-size: 16px !important;
}

.fs-60 {
  font-size: 60px;
}

.fw-500 {
  font-weight: 500;
}

.w-95 {
  width: 95% !important;
}

.ms-21 {
  margin-left: 21px !important;
}

.bg-gray {
  background-color: #f4f4f4 !important;
}

.bg-main {
  background-color: #e5e5e5 !important;
}

.bg-camera {
  color: #c298eb;
}

.bg-btn {
  background-color: #e9ecef !important;
}

.bg-darkgrey {
  background-color: #dddddd !important;
}

.border-left {
  border-left: 1px solid #868e96 !important;
}

.dropdown-toggle::after {
  display: none !important;
}

.dropdown-menu {
  min-width: 50px !important;
}

.media-block {
  justify-content: space-between !important;
  align-items: flex-start !important;
}

.media-block {
  background-repeat: no-repeat !important;
  background-size: contain !important;
  background-position: center center !important;
  min-height: 442px;

  &:hover {
    .media-input {
      opacity: 1;
    }
  }

  &.choose {
    .media-input {
      opacity: 1;
    }
  }
}

.media-input {
  padding: 8px;
  opacity: 0;
  transition: all 0.3s linear;
}

.card-camera {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.modal-dialog-media {
  min-width: 788px !important;
}

.modal-dialog-folder {
  min-width: 1242px !important;
}

.icon-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  width: 26px;
  background-color: #faf2ff;
  color: #2e3338;
  margin-bottom: 4px;
  transition: background-color 0.15s ease-in-out;

  &:hover {
    background-color: #f0d8ff;
  }
}

.card-profile {
  height: 364px;
}

.constr-number {
  display: inline-block;
  width: 10px;
  text-align: center;
}

.constr-item {
  cursor: pointer;
  padding: 4px 8px;
  transition: all 0.15s ease-in-out;
  border-radius: 12px;
  font-size: 14px;
  margin-bottom: 2px;

  &:last-child {
    margin-bottom: 0;
  }

  p {
    color: #2e3338;
  }
}

.color-violet {
  color: #9b42f0;
}

.constr-item:hover {
  background-color: #f7e9ff;
}
.constr-item.active {
  background-color: #f0d8ff;

  p {
    color: #9b42f0;
  }
}

.collection-endpoints {
  width: 400px;
  position: absolute;
  top: 80px;
  right: 21%;
  z-index: 100;
}

.media-endpoints {
  width: 400px;
  position: absolute;
  top: 25%;
  right: 19.5%;
  z-index: 100;
}

.save-preset-line {
  display: flex;
  &__name {
    padding: 10px;
    padding-right: 0px;
    font-size: 16px;
    line-height: 24px;
    border-radius: 6px;
    margin-right: 10px;

    cursor: pointer;

    flex-grow: 2;

    transition: background-color 0.2s linear;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  &__delete {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.2s linear;
    &:hover {
      background-color: #f5f5f5;
    }

    .form-check-input {
      width: 22px;
      height: 13px !important;
      margin-right: 16px;
    }
  }
}

.endpoints {
  i {
    transition: all 0.2s linear;
    &:hover {
      opacity: 0.5;
    }
  }
}

.bg-pink {
  background-color: #f7f8f9;
}

.text-decoration-dashed {
  text-decoration: dashed;
}
