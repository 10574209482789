$modal-lg: 760px;
$modal-xl: 760px;
$secondary: #989898;

// Progress bar
$progress-height: 7px;
$progress-bg: #f0d8ff;
$progress-bar-bg: #9b42f0;

$border-color: #eaeaea;

$grid-gutter-width: 14px;
$spacer: 14px;
$gutters: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
);
