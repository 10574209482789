.headline {
  color: #b077ff;
}

.switch-button {
  border: 1px solid #eaeaea;
  border-right: 0;
  background: #fff;
  color: #989898;
  padding: 8px;
  font-size: 14px;
  max-height: 38px;

  &--active {
    background-color: #faf2ff;
    color: #9b42f0;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
  }

  &:first-child {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;

    &.switch-button--active {
      border-left: 1px solid transparent;
    }
  }

  &:last-child {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    border-right: 1px solid #eaeaea;
  }
}
