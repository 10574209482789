.wrapper {
  display: flex;
  cursor: pointer;

  svg {
    width: 18px;
    height: 18px;
    fill: #c2c2c2;
    transition: all 0.15s ease-in-out;
  }

  &:hover {
    svg {
      fill: #9b42f0;
    }
  }

  &_active {
    svg {
      fill: #c298eb;
    }
  }
}
