.wrapper {
  background-color: #f7f8f9;
  border-radius: 13px;
  padding: 3px;
  margin-bottom: 3px;

  .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 5px;

    .title {
      display: inline-block;
      font-family: "Roboto";
      font-size: 14px;
      font-weight: 500;
      color: #9b42f0;
      margin-right: 14px;
    }
  }

  .delete-button {
    svg {
      fill: #c2c2c2;
      transition: fill 0.15s ease-in-out;
      cursor: pointer;

      &:hover {
        fill: #9b42f0;
      }
    }
  }

  .add-filter-button {
    padding-left: 4px;

    > div {
      background-color: #f7e9ff;
    }

    &:hover {
      background-color: #f7e9ff;
    }
  }
}
