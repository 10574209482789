.wrapper {
  display: flex;
  align-items: center;
  line-height: 1.25;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 16px;
  margin: 0 1px;

  & input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    transition: 0.15s;
    border-radius: 34px;
    border: 1px solid #9b42f0;

    &:before {
      position: absolute;
      content: "";
      height: 12px;
      width: 12px;
      left: 1px;
      bottom: 1px;
      background-color: #9b42f0;
      transition: 0.15s;
      border-radius: 50%;
    }
  }

  input:checked + .slider {
    background-color: #9b42f0;

    &:before {
      background-color: #fff;
    }
  }

  // input:focus + .slider {
  //   box-shadow: 0 0 1px #9b42f0;
  // }

  input:checked + .slider:before {
    transform: translateX(12px);
  }
}

.text-label {
  margin-left: 10px;
  color: #b077ff;
  font-family: Roboto;
}
