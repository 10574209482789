.option {
  border: 0;
  font-size: 14px;
  font-family: "Roboto";
  font-size: 14px;
  padding: 3px 6px;
  background-color: transparent;
  border-radius: 8px;
  transition: all 0.15s ease-in-out;

  &_active {
    background-color: #faf2ff;
    color: #9b42f0;
  }
}
